import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Menu.module.styl'
import logoUnicred from '~/assets/svg/svg-logo-dark.svg'
import AutoComplete from './AutoComplete'
import cn from 'classnames'

interface Props {
  location: Location
}

const Menu = ({ location }: Props) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [searchMobile, setSearchMobile] = useState(false)

  const { departments } = useApi(
    {
      departments: [],
    },
    'page-home'
  )

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }
  const handleClose = () => {
    setNavbarOpen(false)
  }
  const toggleSearch = () => {
    setSearchMobile(!searchMobile)
  }

  const [url, setUrl] = useState('')
  useEffect(() => {
    const newUrl = location.pathname.split('/')
    if (
      newUrl[1] === 'agencias-unicred' ||
      newUrl[1] === 'nucleo-estrategico-unicred' ||
      newUrl[1] === 'unicred-regional-conexao' ||
      newUrl[1] === 'unicred-corretora-conexao'
    ) {
      setUrl(newUrl[1])
    }
  })

  useEffect(() => {
    setSearchMobile(false)
  }, [location])

  return (
    <header className={cn(st.core, searchMobile && st.active)}>
      <Link
        to="/"
        title="Ir para página inicial"
        className={searchMobile && st.active}
      >
        <img src={logoUnicred} alt="Logo da Unicred" />
      </Link>
      <button
        className={cn(st.btnOpenSearch, searchMobile && st.active)}
        onClick={toggleSearch}
      >
        Abrir pesquisa
      </button>
      <AutoComplete />
      <nav>
        <button className={searchMobile && st.active} onClick={handleToggle}>
          Menu<span></span>
        </button>
        <ul className={navbarOpen && st.active}>
          <li>
            <button onClick={handleClose}>Fechar</button>
          </li>
          <li>
            <Link onClick={handleClose} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={handleClose} to="/porque-a-unicred/">
              Porquê a unicred
            </Link>
          </li>
          <li>
            <Link onClick={handleClose} to="/carreira/">
              Carreira
            </Link>
          </li>
          <li>
            <Link onClick={handleClose} to="/competencias/">
              Competências Unicred
            </Link>
          </li>
          <li>
            <Link onClick={handleClose} to="/estrutura-de-cargos/">
              Estrutura de cargos
            </Link>
          </li>
          <li>
            <Link onClick={handleClose} to={`/${url}/principais-entregaveis/`}>
              Principais entregáveis
            </Link>
            <ul>
              {departments.map(({ name, slug }, index) => (
                <li key={index}>
                  <Link
                    onClick={handleClose}
                    to={`/${slug}/principais-entregaveis/`}
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <Link onClick={handleClose} to="/manual-de-remuneracao/">
              Manual de remuneração
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Menu
